.App {
    width: 100%; /* Default width for full screen desktop view */
    height: 100vh; /* Default height for full screen desktop view */
  }


  body{
    overflow-x: hidden;
  }
  
  /* Media query for smaller screens */
  @media (max-width: 768px) {
    .App {
      width: 100%; /* Adjusted width for smaller screens */
      height: 100vh; /* Adjusted height for smaller screens */
      zoom: 0.3; /* Zoom out to make content appear smaller */
    }
  }