.SubCtaegoryBanner{
    margin-top: 7%;
}



.ecommerce-card {
    border: 8px solid #fff;
    text-align: left;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 1px 1px 7px rgba(0, 0, 0, .1);
    -moz-box-shadow: 1px 1px 7px rgba(0, 0, 0, .1);
    -ms-box-shadow: 1px 1px 7px rgba(0, 0, 0, .1);
    -o-box-shadow: 1px 1px 7px rgba(0, 0, 0, .1);
    box-shadow: 1px 1px 7px rgba(0, 0, 0, .1);
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: box-shadow 0.3s, transform 0.3s;
    height: auto;
    transform-style: preserve-3d;
}

.ecommerce-card:hover {
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.5);
  transform: translate3d(0, -5px, 0) scale(1.01);
}

.product-description {
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: #78838e;
}



.AddtoCartBtn {
    background-color: #ee5786;
    color: #fff;
    border: 0;
    font-size: 13px;
    text-transform: uppercase;
    padding: 9px 15px 6px;
    border-radius: 3px;
    font-weight: 500;
}

.AddtoCartBtn:hover {
    color: #fff;
    background-color: #ee5786;
   
}
.AddtoCartBtn:focus-visible {
    outline: 0;
    box-shadow: none;
}

.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
    color: #fff;
    background-color: #ee5786;
   
}


#heartCard {
    color: grey;
    font-size: 24px;
    cursor: pointer;
    padding: 10px;
  }
  
  #heartCard.red {
    color: #ee5786;
  }




  /* HeartAnimation.css */
.heart {
    width: 100px;
    height: 100px;
    background: url("./images//heart.png") no-repeat;
    background-position: 0 0;
    cursor: pointer;
    transition: background-position 1s steps(28);
    transition-duration: 0s;
  }
  .heart.is-active {
    transition-duration: 1s;
    background-position: -2800px 0;
  }
  
 .card-buttons{
    /* height: 30px; */
 }

 .rs-modal-content{
  border-radius: 10px !important;
 }


 
  