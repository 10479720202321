.sidebar-main-content ul li {
    padding: 0.5rem 0.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #3c3b37;
    font-size: 16px;
}


#react-sidebar .section-heading {
    font-size: 1.20rem;
    font-weight: 700;
    color: #3c3b37;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    display: flex;
    align-items: center;
}

#react-sidebar .sidebar-main.show {
    transform: translateX(0px);
    visibility: visible;
    height: 100%;
}


.loginSidebar{
    font-size: 19px;
    line-height: 25px;
    letter-spacing: 1px;
}


#react-sidebar .sidebar-main {
    width: 285px;
    height: 100vh;
    background-color: #fff;
    z-index: 1012;
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    transform: translateX(-300px);
    transition: all 0.2s ease-in-out !important;
    overflow: hidden;
    overflow-y: auto;
}


#react-sidebar .sidebar-header {
    background: #232F3E;
    color: white;
    
}

.sidebar-header .fa-circle-user{
    margin-right: 10px;
    font-weight: 100;
}