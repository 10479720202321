.cartPage{
    margin-top: 7%;
}

.form-control:focus {
    
   
    border-color: none;
    outline: 0;
    box-shadow: none;
}

#addon-wrapping{
    cursor: pointer;
}

.cartItemDesc{
    color: #000000;
    display: block;
    margin-top: 10px;
    max-width: 336px;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: break-word;
    overflow: hidden;
}
