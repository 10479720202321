

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap');
@import "rsuite/dist/rsuite.css";

*{
    font-family: 'Poppins', sans-serif;
}

.mega-menu-content {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100vw;
    display: none;
    background-color: #f9f9f9;
    padding: 20px;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    transition: bottom 0.5s ease;
   
}

.mega-menu:hover .mega-menu-content {
    display: block;
    z-index: 999;
    transform: translateY(0);
}

.mega-menu .mega-menu-content {
    min-width: 100%; 
    max-width: 100vw; 
    overflow-x: hidden; 
    overflow-y: auto; 
    max-height: 80vh; 
}

.mega-menu span:hover{
    cursor: pointer;
}

.navlinkitems li{
    list-style-type: none;
}

  

  .form-check-input:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none !important;
}

#navbarSupportedContent nav-item a{
    font-size: 12px !important;
}

.top-navbar .nav-link{
    display: initial !important;
}


.top-navbar a{
    font-size: 12px !important;
    font-weight: 600;
}

.mega-menu:hover {
    background: red;
}
  .NavbarDown{
    background: #232F3E;
  }


/* .NavbarDown a:focus{
    background: red !important;
} */


.sideicons .nav-link{
    cursor: pointer;
}

.sideiconsTop li a:hover{
   
}
.sideiconsTop li {
    cursor: pointer;
    
}

.sideiconsTop li a{
    font-size: 14px;
    font-weight: 400;
   
}
.sideiconsTopSingle a{
    font-size: 12px;
    font-weight: 400;
}

.sideiconsTopSingle{
    display: flex;
    align-items: center;
}

.sideiconsTopSingle  a:hover{
  
}




.navlinkitems li a {
    color: #54697e !important;
    font-size: 13px;
    margin-bottom: 6px;
    display: block;
    text-decoration: none;
}
.navlinkitems li  {
  line-height: 17px;
}





  
.custom-subcategory-title {
    position: relative;
    max-width: 600px;
    height: auto;
    border: 2px solid  rgb(240, 163, 176);
    padding: 3px;
    box-sizing: border-box;
    width: 50%;
    display: block;
}

.custom-subcategory-title:after {
    position: absolute;
    width: 8px;
    height: 8px;
    border-top: 2px solid  rgb(240, 163, 176);
    border-right: 2px solid  rgb(240, 163, 176);
    border-bottom: 0; 
    border-left: 2px solid  rgb(240, 163, 176);
    top: 100%;
    left: 20%;
    margin-left: 2px;
    content: '';
    transform: rotate(45deg);
    margin-top: -3px;
    background:  rgb(240, 163, 176);
}


.itemsStyle {
    display: inline-block;
    text-decoration: none;
    transition: transform 0.3s ease;
}

.itemsStyle:hover {
    transform: translateX(10px); 
    color:  rgb(240, 163, 176); 
}

.navbartopNav , .navbartopNav1{
    border: none;
    background-color:#D4D4D4;
    
}

.navbartopNav:focus{
    box-shadow: none;
}

.navbartopNav option{
    background: #fff;
}

.navbar-brand img{
    width: 100%;
    height: 40px;
   
}


.fa-cart-shopping:after {
    content: attr(value);
    font-size: 12px;
    color: black;
    background: #31D2F2;
    border-radius: 50%;
    padding: 7px 10px;
    position: relative;
    left: -8px;
    top: -32px;
    opacity: 0.9;
}
.fa-cart-shopping{
    font-size: 45px !important;
}
.wishlistCount:after{
    content:attr(value);
    font-size:12px;
    color: black;
    background: #31D2F2;
    border-radius:50%;
    padding: 0 5px;
    position:relative;
    left:-4px;
    top:-10px;
    opacity:0.9;
}






.cart-tooltip-container {
    position: relative;
  }
  
  
  .cart-tooltip {
    width: 500px;
    display: block;
    position: absolute;
    top: 110px;
    left: -140px;
    transform: translateX(-50%);
    background-color: #fff;
    border: 1px solid #ccc;
   
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
    height: 50vh;
    overflow-y: auto;
  }
  

  .cart-tooltip-container:hover .cart-tooltip {
    display: block;
  }

  .editdelicon .fa-solid{
     border: 1px solid grey;
     border-radius: 50%;
     padding: 6px;
     background: rgb(218, 210, 210);
     font-size: 24px;
  }

  .tollpit-hight-remove{
    height: 8rem;
  }


  #basic-addon2{
    background: #ee5786;
  }

  #basic-addon2:hover{
    background: #bf2052;
  }



.submenu-container {
    position: absolute;
    top: 100%; 
    background-color: #fff; 
    border: 1px solid #ccc;
    padding: 10px;
    display: none; 
    z-index: 999;
  }

 
  
 
  .sideiconsTop .nav-link:hover  .submenu-container {
    display: block;
  }
  

  .rs-dropdown-item:focus-visible, .rs-dropdown-item.rs-dropdown-item-focus {
    background-color: none !important;
   
}

.sideiconsTop{
    --bs-nav-link-padding-y: 0rem !important;
}

.custom-text .fa-bars{
font-size: 15px;
}