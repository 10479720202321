.footerLinks li a {
    color: #54697e !important;
    font-size: 13px;
    margin-bottom: 6px;
    display: block;
    text-decoration: none;
    
}
.footerLinks li{
    list-style-type: none !important;
}

.serchesKeyword a{
    color: #54697e !important;
    font-size: 13px;
    margin-bottom: 6px;
    
    text-decoration: none;
}

.footerLinks li p.footerHeading {
    border-top: 2px solid pink;
    border-bottom: 3px solid rgb(240, 163, 176);
    padding: 6px 0;
    margin: 0;
}
.trendingSearches{
    border-top: 2px solid pink;
    border-bottom: 3px solid rgb(240, 163, 176);
    padding: 6px 0;
    margin: 0;
}


footer {
    background-color: #edeff1 !important;
}

