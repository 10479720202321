.video-container {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
  }
  
  .scaled-video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover; 
  }
  

 

.card-container {
    height: 160px;
    width: auto;
    overflow: hidden;
  }
  
  .card_sizing {
    display: flex; 
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  
  
  @media (max-width: 991.98px) {
    .card-container {
      width: 100%; 
    }
  }


.mainHomeCont{
  margin-top: 5% !important;
}
